<template>
    <div id="pageTable">

        <v-overlay :value="loadingLottie"  :z-index="999" >
            <div class="align-center justify-center text-center">
                <v-progress-circular
                    indeterminate
                    :size="100"
                    :width="7"
                    color="#2DB9D1"
                >
                    <v-img
                        src="/static/icon/favicon.ico"
                        :width="60"
                    ></v-img>
                </v-progress-circular>
                <p class="mt-5">{{fraseLoading}}</p>
            </div>
        </v-overlay>  

        <v-container xl fluid>
            <v-row>
                <v-col cols="12" xs="12" sm="12" md="12" class="mx-0 px-0">
                    <v-breadcrumbs :items="itemsLinks">
                        <template v-slot:divider>
                            <v-icon>chevron_right</v-icon>
                        </template>
                    </v-breadcrumbs>
                </v-col>

                <v-col cols="12" xs="12" sm="6" md="6" lg="6" xl="6" class="pb-0">
                             <div class="pt-2" style="display: flex; width:100%; height:100%; justify-content: flex-start;">
                                <hr class="vr"/>
                                <p class="tblName">Complementos de nómina</p>
                            </div>
                        </v-col>
                        <v-col cols="12" xs="12" sm="6" md="6" lg="6" xl="6" class="pb-0">
                            <div class="float-right pa-0 mb-0 d-flex" >
                                <v-tooltip bottom>
                                    <template v-slot:activator="{ on }">
                                        <v-btn
                                            v-on="on"
                                            fab
                                            color="#1E2245"
                                            right
                                            class="elevation-0 mr-2"
                                            @click="openModal('add')"
                                            width="38"
                                            height="38"
                                        >
                                            <v-icon color="#ffffff" size="20">add</v-icon>
                                        </v-btn>
                                    </template>
                                    <span>Agregar</span>
                                </v-tooltip>
                                <v-menu 
                                    buttom 
                                    rounded 
                                    offset-y 
                                    content-class="menuClassAcciones" 
                                    class="elevation-0 d-inline ml-2"
                                > 
                                    <template v-slot:activator="{ on, attrs }">
                                        <v-btn
                                            v-bind="attrs"
                                            v-on="on"
                                            rounded
                                            color="#58C5D8"
                                            dark
                                            class="btn-agrupar-por justify-space-between elevation-0 mr-2 pr-2"
                                        >
                                            Acciones
                                            <v-icon>keyboard_arrow_down</v-icon>
                                        </v-btn>
                                    </template>

                                    <v-list class="py-0">
                                        <p class="tituloAcciones pl-8 pt-8 mb-1">Reporte</p>
                                        <v-list-item
                                            class="py-0 px-8"
                                            @click="exportar('pdf')"
                                        >   
                                            <v-list-item-title>Descargar PDF</v-list-item-title>
                                        </v-list-item>
                                        <v-list-item
                                            class="py-0 px-8"
                                            @click="exportar('xlsx')"
                                        >   
                                            <v-list-item-title>Descargar documento excel</v-list-item-title>
                                        </v-list-item>
                                    </v-list>
                                    
                                    <template>
                                        <v-divider class="mx-4"></v-divider>
                                        <v-list class="py-0 pb-3">
                                            <p class="tituloAcciones pl-8 pt-5 mb-1">Layout</p>
                                            <v-list-item
                                                class="py-0 px-8"
                                                @click="layoutComplementos()"
                                            >   
                                                <v-list-item-title>Descargar layout de complementos</v-list-item-title>
                                            </v-list-item>
                                            <v-list-item
                                                class="py-0 px-8"
                                                @click="abrirModalLayoutEmpleados()"
                                            >   
                                                <v-list-item-title>Subir complementos</v-list-item-title>
                                            </v-list-item>
                                        </v-list>
                                    </template>

                                    <v-divider class="mx-4"></v-divider>

                                    <v-list 
                                        class="py-0 py-3" 
                                        v-show="modeloTipoGrupo == 'SINORDEN' && btnActualizacionMasiva"
                                    >
                                         
                                        <template v-if="btnActualizacionMasiva">
                                            <v-list-item
                                                class="py-0 px-8"
                                                @click="eliminacionMasiva()"
                                            >   
                                                <v-list-item-title>Eliminar seleccionados</v-list-item-title>
                                            </v-list-item>
                                        </template>
                                    </v-list>
                                    
                                </v-menu>
                                <v-menu buttom rounded offset-y content-class="menuClassAcciones" class="elevation-0 d-inline ml-2"> 
                                    <template v-slot:activator="{ on, attrs }">
                                        <v-btn
                                            v-bind="attrs"
                                            v-on="on"
                                            rounded
                                            class="btn-agrupar-por justify-space-between pr-2 elevation-0"
                                            color="#1E2245"
                                            dark
                                        >
                                            Agrupar por
                                            <v-icon>keyboard_arrow_down</v-icon>
                                        </v-btn>
                                    </template>

                                    <v-list>
                                        <v-list-item
                                            v-for="(item, index) in items"
                                            :key="index"
                                            @click="opcionAgrupar(item)"
                                        >
                                            <v-list-item-title>{{ item.title }}</v-list-item-title>
                                        </v-list-item>
                                    </v-list>
                                </v-menu>
                                
                            </div>
                        </v-col>



                <v-col cols="12" lg="12" class="pa-0 ma-0 pb-9">
                    <!-- TABLA GENERAL -->
                    <simple-table
                        v-if="modeloTipoGrupo == 'SINORDEN'"
                        :isSelectable="true"
                        :selected="selected_general"
                        @selectAllEvent="getSelected_general"
                        :rows="itemsIncidenciasSinOrden"
                        :columns="headersTableGeneral"
                        :perPage="[10, 25, 50, 100]"
                        ref="tablageneral"
                        :loading="loading"
                        :idTabla="'tablaSinOrden'"
                    >
                        <tbody slot="body" slot-scope="{ data }">
                            <tr :key="item.id" v-for="item in data" class="alto-tr">
                                <td style="width:80px !important; padding-right:4px !important;">
                                    <v-checkbox class="mb-5" primary hide-details :value="item.id" v-model="selected_general"></v-checkbox>
                                </td> 
                                <td class="pr-0">
                                    <div>
                                        <v-list color="transparent" class="py-0 my-0">
                                            <v-list-item two-line class="py-0 my-0 px-0">
                                                <v-list-item-avatar class="py-0 my-0" height="40px" width="40px">
                                                    <img :src="urlAvatar + item.foto" alt="Empleado">
                                                </v-list-item-avatar>
                                                <v-list-item-content class="ml-0 py-0 my-0">
                                                    <v-list-item-title class="list-item-titulo text-wrap">{{item.nombre_completo }}</v-list-item-title>
                                                </v-list-item-content>
                                            </v-list-item>
                                        </v-list>
                                    </div>
                                </td>
                                <td class="list-item-titulo"><div>{{item.codigo_tipo_incidencia}} - {{ item.nombre_incidencia}}</div></td>
                                <td class="list-item-titulo"><div>{{ formatoMoneda(item.total)}}</div></td>
                                <td v-if="nominaDesacumulada.tipo_periodo_especial == 'FINIQUITO'">
                                    <div>
                                        {{item.tipo_indemnizacion?'Indemnización':'Finiquito'}}
                                    </div>
                                </td>
                                <td>
                                    <div>
                                        <template>
                                            <v-menu buttom class="elevation-0 d-inline ml-2" content-class="mt-0"> 
                                                <template v-slot:activator="{ on, attrs }">
                                                    <v-btn
                                                        v-bind="attrs"
                                                        v-on="on"
                                                        icon 
                                                        fab
                                                        dark
                                                        small
                                                        class="elevation-0"
                                                    >
                                                        <template  v-for="(item,valor) in attrs" >
                                                            <v-icon :key="valor" v-if="valor == 'aria-expanded' &&  item == 'false'" class="pl-2" color='#828282'>more_vert</v-icon>
                                                            <v-icon :key="valor" v-if="valor == 'aria-expanded' &&  item == 'true'" class="pl-2" color='#2DB9D1'>more_vert</v-icon>
                                                        </template>
                                                    </v-btn>
                                                </template>

                                                <v-list>
                                                    <v-list-item
                                                        @click="abrirModalEdit(item, item, 'SINORDEN')"
                                                    >
                                                        <v-list-item-title style="color:#828282">Modificar complemento</v-list-item-title>
                                                    </v-list-item>
                                                </v-list>
                                            </v-menu>
                                            <v-tooltip bottom>
                                                <template v-slot:activator="{ on }">
                                                    <v-btn v-on="on" class="botonHover" icon fab dark small @click="eliminarIncidencia(item.nombre_incidencia, item.id)">
                                                        <v-icon class="tamIconoBoton invertirColorBotonesDelete">delete</v-icon>
                                                    </v-btn>
                                                </template>
                                                <span>Eliminar</span>
                                            </v-tooltip>
                                        </template>
                                    </div>
                                </td>
                            </tr>
                        </tbody>
                    </simple-table>






                    <!-- TABLA EMPLEADOS -->
                    <simple-table
                        v-if="modeloTipoGrupo == 'EMPLEADOS'"
                        :rows="itemsEmpleados"
                        :columns="headersTableEmpleados"
                        :loading="loading"
                        :perPage="[10, 25, 50, 100]"
                    >
                        <tbody class="tbl-incidencias" slot="body" slot-scope="{ data }">
                            <tr :key="item.id" v-for="(item, index) in data">
                                <td>
                                    <expandable-item :image="urlAvatar + item.foto" :title="item.nombre_completo">
                                        <template slot="content">
                                            <table class="tbl-child">
                                                <thead>
                                                    <th>Incidencia</th>
                                                    <th>Importe</th>
                                                    <th v-if="tipoPeriodoEspecial == 'FINIQUITO'">Tipo</th>
                                                    <th>Opciones</th>
                                                </thead>
                                                <tbody>
                                                    <tr v-for="(incidencia, index2) in item.incidencias" :key="index2">
                                                        <td>{{incidencia.codigo_tipo_incidencia}} - {{ incidencia.nombre_incidencia }}</td>
                                                        <td>{{ formatoMoneda(incidencia.total) }}</td>
                                                        <td v-if="tipoPeriodoEspecial == 'FINIQUITO'">
                                                            {{incidencia.tipo_indemnizacion?'Indemnización':'Finiquito'}}
                                                        </td>
                                                        <td>
                                                            <v-menu buttom class="elevation-0 d-inline ml-2" content-class="mt-0"> 
                                                                <template v-slot:activator="{ on, attrs }">
                                                                    <v-btn
                                                                        v-bind="attrs"
                                                                        v-on="on"
                                                                        icon 
                                                                        fab
                                                                        dark
                                                                        small
                                                                        class="elevation-0"
                                                                    >
                                                                        <template  v-for="(item,valor) in attrs" >
                                                                            <v-icon :key="valor" v-if="valor == 'aria-expanded' &&  item == 'false'" class="pl-2" color='#828282'>more_vert</v-icon>
                                                                            <v-icon :key="valor" v-if="valor == 'aria-expanded' &&  item == 'true'" class="pl-2" color='#2DB9D1'>more_vert</v-icon>
                                                                        </template>
                                                                    </v-btn>
                                                                </template>

                                                                <v-list>
                                                                    <v-list-item
                                                                        @click="abrirModalEdit(item, incidencia, 'EMPLEADOS')"
                                                                    >
                                                                        <v-list-item-title style="color:#828282">Modificar complemento</v-list-item-title>
                                                                    </v-list-item>
                                                                </v-list>
                                                            </v-menu>
                                                            <v-tooltip bottom>
                                                                <template v-slot:activator="{ on }">
                                                                    <v-btn v-on="on" class="botonHover" icon fab dark small @click="eliminarIncidencia(incidencia.nombre_incidencia, incidencia.id)">
                                                                        <v-icon class="tamIconoBoton invertirColorBotonesDelete">delete</v-icon>
                                                                    </v-btn>
                                                                </template>
                                                                <span>Eliminar</span>
                                                            </v-tooltip>
                                                        </td>
                                                    </tr>
                                                </tbody>
                                            </table>
                                        </template>
                                    </expandable-item>

                                    <hr v-if="index < data.length - 1" />
                                </td>
                            </tr>
                        </tbody>
                    </simple-table>

                    <!-- TABLA INCIDENCIAS -->
                    <simple-table
                        v-if="modeloTipoGrupo == 'INCIDENCIAS'"
                        :rows="itemsIncidencias"
                        :columns="headersTableIncidencias"
                        :perPage="[10, 25, 50, 100]"
                        :loading="loading"
                    >
                        <tbody class="tbl-incidencias" slot="body" slot-scope="{ data }">
                            <tr :key="item.id" v-for="(item, index) in data">
                                <td>
                                    <expandable-item :title="item.codigo + ' - ' +  item.nombre" :numero="item.empleados.length">
                                        <template slot="content">
                                            <table class="tbl-child">
                                                <thead>
                                                    <th>Empleado</th>
                                                    <th>Importe</th>
                                                    <th v-if="tipoPeriodoEspecial == 'FINIQUITO'">Tipo</th>
                                                    <th>Opciones</th>
                                                </thead>
                                                <tbody>
                                                    <tr v-for="empleado in item.empleados" :key="empleado.nombre">
                                                        <td>
                                                            <v-list color="transparent" class="py-0 my-0">
                                                                <v-list-item two-line class="py-0 my-0 px-0">
                                                                    <v-list-item-avatar class="py-0 my-0" height="40px" width="40px">
                                                                        <img :src="urlAvatar+empleado.foto" alt="Empleado">
                                                                    </v-list-item-avatar>
                                                                    <v-list-item-content class="ml-0 py-0 my-0">
                                                                        <v-list-item-title class="list-item-titulo">{{empleado.nombre_completo }}</v-list-item-title>
                                                                    </v-list-item-content>
                                                                </v-list-item>
                                                            </v-list>
                                                        </td>
                                                        <td>{{ formatoMoneda(empleado.total)}}</td>
                                                        <td v-if="tipoPeriodoEspecial == 'FINIQUITO'">
                                                            <div>
                                                                {{empleado.tipo_indemnizacion?'Indemnización':'Finiquito'}}
                                                            </div>
                                                        </td>
                                                        <td>
                                                            <v-menu buttom class="elevation-0 d-inline ml-2" content-class="mt-0"> 
                                                                <template v-slot:activator="{ on, attrs }">
                                                                    <v-btn
                                                                        v-bind="attrs"
                                                                        v-on="on"
                                                                        icon 
                                                                        fab
                                                                        dark
                                                                        small
                                                                        class="elevation-0"
                                                                    >
                                                                        <template  v-for="(item,valor) in attrs" >
                                                                            <v-icon :key="valor" v-if="valor == 'aria-expanded' &&  item == 'false'" class="pl-2" color='#828282'>more_vert</v-icon>
                                                                            <v-icon :key="valor" v-if="valor == 'aria-expanded' &&  item == 'true'" class="pl-2" color='#2DB9D1'>more_vert</v-icon>
                                                                        </template>
                                                                    </v-btn>
                                                                </template>

                                                                <v-list>
                                                                    <v-list-item
                                                                        @click="abrirModalEdit(item, empleado, 'INCIDENCIAS')"
                                                                    >
                                                                        <v-list-item-title style="color:#828282">Modificar complemento</v-list-item-title>
                                                                    </v-list-item>
                                                                </v-list>
                                                            </v-menu>
                                                            <v-tooltip bottom>
                                                                <template v-slot:activator="{ on }">
                                                                    <v-btn v-on="on" class="botonHover" icon fab dark small @click="eliminarIncidencia(item.nombre, empleado.id)">
                                                                        <v-icon class="tamIconoBoton invertirColorBotonesDelete">delete</v-icon>
                                                                    </v-btn>
                                                                </template>
                                                                <span>Eliminar</span>
                                                            </v-tooltip>
                                                        </td>
                                                    </tr>
                                                </tbody>
                                            </table>
                                        </template>
                                    </expandable-item>

                                    <hr v-if="index < data.length - 1"/>
                                </td>
                            </tr>
                        </tbody>
                    </simple-table>




                    <!-- TABLAS PERCEPCIONES-DEDUCIONES-OTROS_PAGOS -->
                    <simple-table
                        v-if="modeloTipoGrupo == 'PERCEPCIONES-DEDUCIONES-OTROS_PAGOS'"
                        :rows="itemsDeducciones"
                        :columns="tipoPeriodoEspecial == 'FINIQUITO'?headersTableGeneralFiniquito:headersTableGeneral"
                        :perPage="[10, 25, 50, 100]"
                        ref="tablageneral"
                        :idTabla="'tablaDeducciones'"
                        :loading="loading"
                        
                    >
                        <tbody slot="body" slot-scope="{ data }">
                            <tr :key="item.id" v-for="item in data" class="alto-tr">
                                <td class="pr-0">
                                    <div>
                                        <v-list color="transparent" class="py-0 my-0">
                                            <v-list-item two-line class="py-0 my-0 px-0">
                                                <v-list-item-avatar class="py-0 my-0" height="40px" width="40px">
                                                    <img :src="urlAvatar + item.foto" alt="Empleado">
                                                </v-list-item-avatar>
                                                <v-list-item-content class="ml-0 py-0 my-0">
                                                    <v-list-item-title class="list-item-titulo text-wrap">{{item.nombre_completo }}</v-list-item-title>
                                                </v-list-item-content>
                                            </v-list-item>
                                        </v-list>
                                    </div>
                                </td>
                                <td class="list-item-titulo"><div>{{item.codigo_tipo_incidencia}} - {{ item.nombre_incidencia}}</div></td>
                                <td class="list-item-titulo"><div>{{ formatoMoneda(item.total)}}</div></td>

                                <td v-if="tipoPeriodoEspecial == 'FINIQUITO'">
                                    <div>
                                        {{item.tipo_indemnizacion?'Indemnización':'Finiquito'}}
                                    </div>
                                </td>
                                <td>
                                    <div>
                                        <v-menu buttom class="elevation-0 d-inline ml-2" content-class="mt-0"> 
                                            <template v-slot:activator="{ on, attrs }">
                                                <v-btn
                                                    v-bind="attrs"
                                                    v-on="on"
                                                    icon 
                                                    fab
                                                    dark
                                                    small
                                                    class="elevation-0"
                                                >
                                                    <template  v-for="(item,valor) in attrs" >
                                                        <v-icon :key="valor" v-if="valor == 'aria-expanded' &&  item == 'false'" class="pl-2" color='#828282'>more_vert</v-icon>
                                                        <v-icon :key="valor" v-if="valor == 'aria-expanded' &&  item == 'true'" class="pl-2" color='#2DB9D1'>more_vert</v-icon>
                                                    </template>
                                                </v-btn>
                                            </template>

                                            <v-list>
                                                <v-list-item
                                                    @click="abrirModalEdit(item, item, 'PERCEPCIONES-DEDUCIONES-OTROS_PAGOS')"
                                                >
                                                    <v-list-item-title>Modificar complemento</v-list-item-title>
                                                </v-list-item>
                                            </v-list>
                                        </v-menu>
                                        <v-tooltip bottom>
                                            <template v-slot:activator="{ on }">
                                                <v-btn v-on="on" class="botonHover" icon fab dark small @click="eliminarIncidencia(item.nombre_incidencia, item.id)">
                                                    <v-icon class="tamIconoBoton invertirColorBotonesDelete">delete</v-icon>
                                                </v-btn>
                                            </template>
                                            <span>Eliminar</span>
                                        </v-tooltip>
                                    </div>
                                </td>
                            </tr>
                        </tbody>
                    </simple-table>
                    <v-col cols="12" xs="12" sm="12" md="12" class="py-4 px-0 d-flex justify-end">
                        <v-card  
                            v-if="modeloTipoGrupo == 'PERCEPCIONES-DEDUCIONES-OTROS_PAGOS'" 
                            class="pa-5"
                            style="border-radius: 15px;"
                            elevation="0"
                        >
                            <span class="textResultadoTotal">Total de deducciones: </span><span class="numberResultadoTotal">{{formatoMoneda(totalDeducciones)}}</span> 
                        </v-card>
                    </v-col>
                    

                    
                    <simple-table
                        v-if="modeloTipoGrupo == 'PERCEPCIONES-DEDUCIONES-OTROS_PAGOS'"
                        :rows="itemsPercepciones"
                        :columns="tipoPeriodoEspecial == 'FINIQUITO'?headersTableGeneralFiniquito:headersTableGeneral"
                        :perPage="[10, 25, 50, 100]"
                        ref="tablageneral"
                        :idTabla="'tablaPercepciones'"
                        :loading="loading"
                    >
                        <tbody slot="body" slot-scope="{ data }">
                            <tr :key="item.id" v-for="item in data" class="alto-tr">
                                <td class="pr-0">
                                    <div>
                                        <v-list color="transparent" class="py-0 my-0">
                                            <v-list-item two-line class="py-0 my-0 px-0">
                                                <v-list-item-avatar class="py-0 my-0" height="40px" width="40px">
                                                    <img :src="urlAvatar + item.foto" alt="Empleado">
                                                </v-list-item-avatar>
                                                <v-list-item-content class="ml-0 py-0 my-0">
                                                    <v-list-item-title class="list-item-titulo text-wrap">{{item.nombre_completo }}</v-list-item-title>
                                                </v-list-item-content>
                                            </v-list-item>
                                        </v-list>
                                    </div>
                                </td>
                                <td class="list-item-titulo"><div>{{item.codigo_tipo_incidencia}} - {{ item.nombre_incidencia}}</div></td>
                                <td class="list-item-titulo"><div>{{ formatoMoneda(item.total)}}</div></td>
                                <td v-if="tipoPeriodoEspecial == 'FINIQUITO'">
                                    <div>
                                        {{item.tipo_indemnizacion?'Indemnización':'Finiquito'}}
                                    </div>
                                </td>
                                <td>
                                    <div>
                                        <v-menu buttom class="elevation-0 d-inline ml-2" content-class="mt-0"> 
                                            <template v-slot:activator="{ on, attrs }">
                                                <v-btn
                                                    v-bind="attrs"
                                                    v-on="on"
                                                    icon 
                                                    fab
                                                    dark
                                                    small
                                                    class="elevation-0"
                                                >
                                                    <template  v-for="(item,valor) in attrs" >
                                                        <v-icon :key="valor" v-if="valor == 'aria-expanded' &&  item == 'false'" class="pl-2" color='#828282'>more_vert</v-icon>
                                                        <v-icon :key="valor" v-if="valor == 'aria-expanded' &&  item == 'true'" class="pl-2" color='#2DB9D1'>more_vert</v-icon>
                                                    </template>
                                                </v-btn>
                                            </template>

                                            <v-list>
                                                <v-list-item
                                                    @click="abrirModalEdit(item, item, 'PERCEPCIONES-DEDUCIONES-OTROS_PAGOS')"
                                                >
                                                    <v-list-item-title style="color:#828282">Modificar complemento</v-list-item-title>
                                                </v-list-item>
                                            </v-list>
                                        </v-menu>
                                        <v-tooltip bottom>
                                            <template v-slot:activator="{ on }">
                                                <v-btn v-on="on" class="botonHover" icon fab dark small @click="eliminarIncidencia(item.nombre_incidencia, item.id)">
                                                    <v-icon class="tamIconoBoton invertirColorBotonesDelete">delete</v-icon>
                                                </v-btn>
                                            </template>
                                            <span>Eliminar</span>
                                        </v-tooltip>
                                    </div>
                                </td>
                            </tr>
                        </tbody>
                    </simple-table>
                    <v-col cols="12" xs="12" sm="12" md="12" class="py-4 px-0 d-flex justify-end">
                        <v-card  
                            v-if="modeloTipoGrupo == 'PERCEPCIONES-DEDUCIONES-OTROS_PAGOS'" 
                            class="pa-5"
                            style="border-radius: 15px;"
                            elevation="0"
                        >
                            <span class="textResultadoTotal">Total de percepciones: </span><span class="numberResultadoTotal">{{formatoMoneda(totalPercepciones)}}</span> 
                        </v-card>
                    </v-col>

                   
                    <simple-table 
                        v-if="modeloTipoGrupo == 'PERCEPCIONES-DEDUCIONES-OTROS_PAGOS'"
                        :rows="itemsOtrosPagos"
                        :columns="tipoPeriodoEspecial == 'FINIQUITO'?headersTableGeneralFiniquito:headersTableGeneral"
                        :perPage="[10, 25, 50, 100]"
                        ref="tablageneral"
                        :idTabla="'tablaOtrosPagos'"
                        :loading="loading"
                    >
                        <tbody slot="body" slot-scope="{ data }">
                            <tr :key="item.id" v-for="item in data" class="alto-tr">
                                <td class="pr-0">
                                    <div>
                                        <v-list color="transparent" class="py-0 my-0">
                                            <v-list-item two-line class="py-0 my-0 px-0">
                                                <v-list-item-avatar class="py-0 my-0" height="40px" width="40px">
                                                    <img :src="urlAvatar + item.foto" alt="Empleado">
                                                </v-list-item-avatar>
                                                <v-list-item-content class="ml-0 py-0 my-0">
                                                    <v-list-item-title class="list-item-titulo text-wrap">{{item.nombre_completo }}</v-list-item-title>
                                                </v-list-item-content>
                                            </v-list-item>
                                        </v-list>
                                    </div>
                                </td>
                                <td class="list-item-titulo"><div>{{item.codigo_tipo_incidencia}} - {{ item.nombre_incidencia}}</div></td>
                                <td class="list-item-titulo"><div>{{ formatoMoneda(item.total)}}</div></td>
                                <td v-if="tipoPeriodoEspecial == 'FINIQUITO'">
                                    <div>
                                        {{item.tipo_indemnizacion?'Indemnización':'Finiquito'}}
                                    </div>
                                </td>
                                <td>
                                    <div>
                                        <v-menu class="elevation-0 d-inline ml-2" content-class="mt-0"> 
                                            <template v-slot:activator="{ on, attrs }">
                                                <v-btn
                                                    v-bind="attrs"
                                                    v-on="on"
                                                    icon 
                                                    fab
                                                    dark
                                                    small
                                                    class="elevation-0"
                                                >
                                                    <template  v-for="(item,valor) in attrs" >
                                                        <v-icon :key="valor" v-if="valor == 'aria-expanded' &&  item == 'false'" class="pl-2" color='#828282'>more_vert</v-icon>
                                                        <v-icon :key="valor" v-if="valor == 'aria-expanded' &&  item == 'true'" class="pl-2" color='#2DB9D1'>more_vert</v-icon>
                                                    </template>
                                                </v-btn>
                                            </template>

                                            <v-list>
                                                <v-list-item
                                                    @click="abrirModalEdit(item, item, 'PERCEPCIONES-DEDUCIONES-OTROS_PAGOS')"
                                                >
                                                    <v-list-item-title>Modificar complemento</v-list-item-title>
                                                </v-list-item>
                                            </v-list>
                                        </v-menu>
                                    </div>
                                </td>
                            </tr>
                        </tbody>
                    </simple-table>
                    <v-col cols="12" xs="12" sm="12" md="12" class="py-4 px-0 d-flex justify-end">
                        <v-card  
                            v-if="modeloTipoGrupo == 'PERCEPCIONES-DEDUCIONES-OTROS_PAGOS'" 
                            class="pa-5"
                            style="border-radius: 15px;"
                            elevation="0"
                        >
                            <span class="textResultadoTotal">Otros pagos: </span><span class="numberResultadoTotal">{{formatoMoneda(totalOtrosPagos)}}</span> 
                        </v-card>
                    </v-col>

                    
                </v-col>
            </v-row>







            <template class="borde-card">
                <v-dialog v-model="dialogEdit" persistent :max-width="maxWidthModal" class="borde-card">
                    <template v-slot:activator="{ on }">
                        <v-btn v-on="on" class="botonModal" id="btnModal"></v-btn>
                    </template>
                    <v-card class="borde-card">
                        <ValidationObserver ref="form" v-slot="{ invalid }">
                            <v-card-title class="card_titulo card_texto pb-5">
                                <div class="headerModal">
                                    <!-- <img :src="imagen" class="imgModal" /> -->
                                    <h2 class="titleModal">Modificar complemento</h2>
                                </div>
                            </v-card-title>

                            <v-card-text class="card_texto">
                                <div id="padre" v-show="isLoading">
                                    <div id="loading">
                                        <v-progress-circular indeterminate :size="100" :width="7" color="blue"></v-progress-circular> 
                                    </div>
                                </div>
                                
                                <v-container v-show="!isLoading" grid-list-md id="contenedor">
                                   <v-row class="">
                                        <v-col cols="12" xs="12" sm="12" md="12" lg="12" xl="12" class="pb-0">
                                            <VuetifyMoneyValidate
                                                rules="required"
                                                outlined
                                                label="Total"
                                                class="pa-0 ma-0"
                                                prefix="$"
                                                v-model="totalComplemento" 
                                                v-bind:options="options"
                                            />
                                        </v-col>
                                        <template v-if="showPercepciones">
                                            
                                            <v-col cols="12" xs="12" sm="12" md="6" lg="6" xl="6" class="py-0">
                                                <VuetifyMoneyValidate
                                                    rules="required"
                                                    outlined
                                                    label="Exento ISR"
                                                    class="pa-0 ma-0"
                                                    prefix="$"
                                                    v-model="isrExento" 
                                                    v-bind:options="options"
                                                />
                                            </v-col>
                                            <v-col cols="12" xs="12" sm="12" md="6" lg="6" xl="6" class="py-0">
                                                <VuetifyMoneyValidate
                                                    rules="required"
                                                    outlined
                                                    label="Gravado ISR"
                                                    class="pa-0 ma-0"
                                                    prefix="$"
                                                    v-model="isrGravado" 
                                                    v-bind:options="options"
                                                    disabled
                                                />
                                            </v-col>
                                            <v-col cols="12" xs="12" sm="12" md="6" lg="6" xl="6" class="py-0">
                                                <VuetifyMoneyValidate
                                                    rules="required"
                                                    outlined
                                                    label="Exento IMSS"
                                                    class="pa-0 ma-0"
                                                    prefix="$"
                                                    v-model="imssExento" 
                                                    v-bind:options="options"
                                                />
                                            </v-col>
                                            <v-col cols="12" xs="12" sm="12" md="6" lg="6" xl="6" class="py-0">
                                                <VuetifyMoneyValidate
                                                    rules="required"
                                                    outlined
                                                    label="Gravado IMSS"
                                                    class="pa-0 ma-0"
                                                    prefix="$"
                                                    v-model="imssGravado" 
                                                    v-bind:options="options"
                                                    disabled
                                                />
                                            </v-col>
                                        </template>
                                    </v-row>
                                </v-container>
                            </v-card-text>
                            <v-card-actions class="d-flex justify-end card_accion pt-4">
                                <button class="btnCerrar modal-pull-right" @click="cerrarModal()" :disabled="isSaving">Cerrar</button>
                                <v-btn class="btnGuardar modal-pull-right" @click="guardarComplemento()" :disabled="invalid" :loading="isSaving">Guardar</v-btn>
                            </v-card-actions>
                        </ValidationObserver>
                    </v-card>
                </v-dialog>
            </template>
        </v-container>
        <template class="borde-card">
            <v-dialog v-model="dialogComplementos" persistent max-width="400px" class="borde-card">
                <v-card class="borde-card">
                    <ValidationObserver ref="form" v-slot="{ invalid }">
                        <v-card-title class="card_titulo padding_izquierdo padding_derecho">
                            <div class="headerModalStart">
                                <h2 class="titleModal_700_18">Subir complementos de nómina</h2>
                            </div>
                        </v-card-title>

                        <v-card-text class="card_texto">
                            <v-container grid-list-md class="pa-0 mt-4">
                                <v-row class="ma-0 pa-0">
                                    <v-col cols="12" xs="12" sm="12" md="12" lg="12" xl="12" class="pa-0 ma-0">
                                            <ValidationProvider v-slot="{ errors }" name="Layout" rules="required">
                                            <v-file-input
                                                outlined
                                                label="Seleccionar el documento"
                                                v-model="archivo"
                                                :accept="'.xlsx'"
                                                required 
                                                :error-messages="errors"
                                                prepend-icon=""
                                                append-icon="attach_file"
                                                id="fileDocument"
                                                @click:append="open"
                                                class="pa-0"
                                            >
                                            </v-file-input>
                                        </ValidationProvider>
                                    </v-col>
                                </v-row>
                            </v-container>
                        </v-card-text>
                        <v-card-actions  class="d-flex justify-end card_accion pt-0">
                            <button class="btnCerrar" @click="cerrarModal()" :disabled="isSaving" >Cerrar</button>
                            <v-btn class="btnGuardar" @click="subirComplementos()" :disabled="invalid" :loading="isSaving">Guardar</v-btn>   
                        </v-card-actions>
                    </ValidationObserver>
                </v-card>
            </v-dialog>
        </template>
        <template class="borde-card">
            <v-dialog v-model="dialogErrores" persistent max-width="950px" class="borde-card">
                <template v-slot:activator="{ on }">
                    <v-btn v-on="on" class="botonModal" id="btnModalErrores"></v-btn>
                </template>
                <v-card class="borde-card">
                    <v-card-title class="padding_izquierdo padding_derecho padding_arriba">
                            <v-btn
                                @click="cerrarModalErrores()"
                                small
                                slot="activator"
                                icon
                                text
                                class="v-btn-cerrar modal-pull-right"
                                absolute
                                top
                                right
                            >
                                <v-icon class="icono-cerrar"></v-icon>
                            </v-btn>
                        <div class="headerModalStart">
                            <h2 class="titleModalErrores">Errores de complementos</h2>
                        </div>
                    </v-card-title>

                    <v-card-text class="padding_izquierdo padding_derecho padding_abajo">
                        <div id="padre" v-show="isLoading">
                            <div id="loading">
                                <v-progress-circular indeterminate :size="100" :width="7" color="blue"></v-progress-circular> 
                            </div>
                        </div>
                        
                        <v-container v-show="!isLoading" grid-list-md id="contenedor">
                            <v-row class="pa-0 ma-0">
                                <v-col cols="12" xs="12" sm="12" md="12" class="pa-0 ma-0">
                                    <table class="tbl-errores">
                                        <thead class="tbl-header">
                                            <tr>
                                                <th style="border-top-left-radius: 20px;" class="pl-7 errorth">Empleado</th>
                                                <th class="errorth">Complemento</th>
                                                <th style="border-top-right-radius: 20px;" class="errorth">Tipo de error</th>
                                            </tr>
                                        </thead>
                                        <tbody>
                                            <tr v-if="erroresComplementos.length == 0" >
                                                <td class="emptyTableErrores" colspan="3">
                                                    No hay elementos para mostrar
                                                </td>
                                            </tr>
                                            <template v-else v-for="(error, index) in erroresComplementos">
                                                <template>
                                                    <tr :key="index">
                                                        <td class="pl-7 errortd pt-4 pb-0">
                                                            <div class="textoTablaError01">{{error.erroresComplementos[0].empleado_nombre}}</div>
                                                            <div class="textoTablaError02">{{error.erroresComplementos[0].departamento}}</div>
                                                        </td>
                                                        <td class="errortd"> <div class="textoTablaError01">{{error.erroresComplementos[0].complemento}}</div></td>
                                                        <td class="errortd"> <div class="textoTablaError03">{{error.erroresComplementos[0].error}}</div> </td>
                                                    </tr>
                                                    <template v-if="error.erroresComplementos.length > 1">
                                                        <tr :key="index2" v-for="(item, index2 ) in error.erroresComplementos.slice(1)">
                                                            <td class="pl-7 errortd"></td>
                                                            <td class="errortd"><div class="textoTablaError01">{{item.complemento}}</div></td>
                                                            <td class="errortd"><div class="textoTablaError03">{{item.error}}</div></td>
                                                        </tr>
                                                    </template>
                                                    <tr :key="'A'+index" class="trDivider"><div class="my-2"></div></tr>
                                                </template>
                                            </template>
                                        </tbody>
                                    </table>
                                </v-col>
                            </v-row>
                        </v-container>
                    </v-card-text>
                </v-card>
            </v-dialog>
        </template>
    </div>
</template>
<script>
import Vue from "vue"
//API's
import apiCalculoNomina from '@/api/nominas/calculoNomina';
import apiIncidenciasNominas from '@/api/nominas/incidencias';
import apiPeriodoIncidencia from '@/api/periodoIncidencia';

//COMPONENTES
import DatatableSimple from '@/components/simpletable/Datatable.vue';
import VuetifyMoneyValidate from '@/components/VuetifyMoneyValidate.vue';
import ExpandableItem from '@/components/ExpandableItem.vue';


import ModalComplementos from '@/views/ModalComplementosDesacumulados.vue';

//PLUGINS
import urlImg from '@/plugins/Urlimagen';
import Notify from '@/plugins/notify';

export default {
    components: {
        "simple-table": DatatableSimple,
        VuetifyMoneyValidate,
        ExpandableItem
    },
    data() {
        return {
            descripcion_periodo         : "",
            nominaDesacumulada          : null,
            idPeriodo                   : null,
            idPeriodoDeComplementos     : null,
            tipoPeriodoEspecial         : null,
            loadingLottie               : false,
            fraseLoading                : "Cargando...",
            modeloTipoGrupo             : 'SINORDEN',
            btnActualizacionMasiva      : false,
            loading                     : false,
            selected_general            : [],
            itemsIncidenciasSinOrden    : [],
            itemsEmpleados              : [],
            itemsIncidencias            : [],
            itemsDeduccionesPercepciones: [],
            itemsDeducciones            : [],
            itemsPercepciones           : [],
            itemsOtrosPagos             : [],
            erroresComplementos         : [],
            totalDeducciones            : 0,
            totalPercepciones           : 0,
            totalOtrosPagos             : 0,
            urlAvatar                   : null,
            isLoading                   : false,
            imagen                      : "",
            dialogEdit                  : false,
            dialogErrores               : false,
            complemento                 : null,
            totalComplemento            : 0,
            estatusComplemento          : null,
            repetirComplemento          : null,
            maxWidthModal               : "400px",
            showPercepciones            : false,
            isrExento                   : 0,
            imssExento                  : 0,
            isSaving                    : false,
            archivo                     : null,
            tipo                        : '',
            dialogComplementos          : false,
            datosLogin                  : null,

            itemsLinks      : [
                {
                    text: 'Nóminas desacumuladas',
                    disabled: false,
                    to: '/desacumulados',
                },
                {
                    text: 'Complementos',
                    disabled: true,
                    to: '/desacumuladosComplementos',
                },
            ],
            headersTableGeneral: [
                { label: "Empleado",    name: "nombre_completo",    filterable: false },
                { label: "Complementos", name: "nombre_incidencia",  filterable: false },
                { label: "Total",       name: "total",              filterable: false },
                { label: "Opciones",    name: "opciones",           filterable: false },
            ],
            headersTableEmpleados: [
                { label: "Ver grupo de empleados",    name: "nombre_completo",    filterable: false },
            ],
            headersTableIncidencias: [
                { label: "Ver lista de complementos de nómina por empleado",    name: "nombre",    filterable: false },
            ],
            items: [
                { title: 'General', valor: 'SINORDEN', icon: 'assignment' },
                { title: 'Empleados', valor: 'EMPLEADOS', icon: 'groups' },
                { title: 'Complementos', valor: 'INCIDENCIAS', icon: 'sms_failed' },
                { title: 'Deducciones-Percepciones-Otros pagos', valor: 'PERCEPCIONES-DEDUCIONES-OTROS_PAGOS', icon: '$price_change_sharp' }
            ],
            options: {
                locale: "es-MX",
                prefix: "$",
                suffix: "",
                length: 11,
                precision: 2
            },
        }
    },
    computed:{
        isrGravado(){
            return this.totalComplemento-this.isrExento
        },
        imssGravado(){
            return this.totalComplemento-this.imssExento
        }
    },
    watch: {
        modeloTipoGrupo:function(value) {
            if(value != null){
                this.getComplementos(value);
            }
        },
        selected_general:function(value) {
            if(value.length > 0 ){
                this.btnActualizacionMasiva =  true;
            }
            else{
                this.btnActualizacionMasiva =  false;
            }
        },
    },
    methods: {
        async getComplementos(orden="SINORDEN"){
            this.loading = true;
            let ordenTipo;
            if(orden == "PERCEPCIONES-DEDUCIONES-OTROS_PAGOS"){
                ordenTipo = "SINORDEN";
            }
            else{
                ordenTipo = orden;
            }
            
            let param = {
                nomina_id: this.nominaDesacumulada.id,
                orden: ordenTipo
            }

            await apiCalculoNomina.getComplementosDesacumulados(param).then(response => {
                this.itemsIncidenciasSinOrden = response.data
                this.idPeriodoDeComplementos = response.periodo_incidencia_id
                this.tipoPeriodoEspecial = response.tipo_periodo_especial
                this.idPeriodo = response.periodo_id


                if(orden === 'INCIDENCIAS'){
                    this.itemsIncidencias = response.data;
                }
                else if(orden === 'EMPLEADOS'){
                    this.itemsEmpleados = []
                    this.itemsEmpleados = response.data;
                }
                else if(orden === 'SINORDEN'){
                    this.itemsIncidenciasSinOrden =  response.data;
                }
                else if(orden === 'PERCEPCIONES-DEDUCIONES-OTROS_PAGOS'){
                    this.itemsDeduccionesPercepciones =  response.data;
                    this.itemsDeducciones = [];
                    this.itemsPercepciones = [];
                    this.itemsOtrosPagos = [];
                    this.totalDeducciones = 0;
                    this.totalPercepciones = 0;
                    this.totalOtrosPagos = 0;
                    this.itemsDeduccionesPercepciones.forEach(element => {
                        
                        switch (element.codigo_tipo_incidencia.charAt(0)) {
                            case 'D':
                                this.itemsDeducciones.push(element);
                                this.totalDeducciones +=  parseFloat(element.total);
                                break;
                            case 'P':
                                this.itemsPercepciones.push(element);
                                this.totalPercepciones += parseFloat(element.total);
                                break;
                            case 'O':
                                this.itemsOtrosPagos.push(element);
                                this.totalOtrosPagos += parseFloat(element.total);
                                break;
                            default:
                                break;
                        }
                    });
                }


                this.loading = false


            })
            .catch(err => {
                this.loading = false
                if(typeof err.response.data.error === 'string'){
                    Notify.ErrorToast(err.response.data.error);
                }else{
                    let error = Object.values(err.response.data.error);
                    let leyenda = ``;

                    for (var i = 0; i < error.length; i++) {
                        leyenda+= `* ` + error[i] + `\n`;
                    }
                    Notify.ErrorToast(leyenda);
                }
            });
        },
        getSelected_general(isSelected, items) {
            this.selected_general = items;
        },
        formatoMoneda(value){
            const formatter = new Intl.NumberFormat('es-MX', {
                style: 'currency',
                currency: 'MXN',
            })
            return formatter.format(value)
        },
        formatoEstatus(estatus){
            switch (estatus) {
                case 'REVISION':
                    return 'Revisión';
                    break;
                case 'RECHAZADO':
                    return 'Rechazado';
                    break;
                case 'AUTORIZADO':
                    return 'Autorizado';
                    break;
                case 'CAPTURA':
                    return 'Captura';
                    break;
                case 'RECAPTURA':
                    return 'Recaptura';
                    break;
                case 'PENDIENTE':
                    return 'Pendiente';
                    break;
                default:
                    return '';
                    break;
            }
        },
        colorEstatus(estatus){
            switch (estatus) {
                case 'REVISION':
                    return '#FCAA7C';
                    break;
                case 'RECHAZADO':
                    return '#F86868';
                    break;
                case 'AUTORIZADO':
                    return '#86EF83';
                    break;
                case 'CAPTURA':
                    return '#FCAA7C';
                    break;
                case 'PENDIENTE':
                    return '#FED543';
                    break;
                default:
                    return '';
                    break;
            }
        },
        abrirModalEdit(data = null, data2 = null, tipoTabla = null){
            this.isLoading  = true;
            this.imagen     = "/static/icon/catalogos.png";

            if(tipoTabla == 'SINORDEN'){
                this.complemento = data;
                this.totalComplemento =  data.total;

                if(data.tipo_concepto == 'percepciones'){
                    this.maxWidthModal = '500px'
                    this.showPercepciones = true
                    this.isrExento  = data.excento_isr;
                    this.imssExento = data.excento_imss;
                }
                else{
                    this.maxWidthModal = '400px'
                    this.showPercepciones = false
                }
            }
            else if(tipoTabla == 'EMPLEADOS'){
                this.complemento = data2;
                this.totalComplemento =  data2.total;
                this.estatusComplemento =  data2.estatus;
                this.repetirComplemento =  data2.repetir;

                if(data2.tipo_concepto == 'percepciones'){
                    this.maxWidthModal = '500px'
                    this.showPercepciones = true
                    this.isrExento  = data2.excento_isr;
                    this.imssExento = data2.excento_imss;
                }
                else{
                    this.maxWidthModal = '400px'
                    this.showPercepciones = false
                }
            }
            else if(tipoTabla == 'INCIDENCIAS'){
                this.complemento = data2;
                this.totalComplemento =  data2.total;
                this.estatusComplemento =  data2.estatus;
                this.repetirComplemento =  data2.repetir;

                if(data.tipo_concepto == 'percepciones'){
                    this.maxWidthModal = '500px'
                    this.showPercepciones = true
                    this.isrExento  = data2.excento_isr;
                    this.imssExento = data2.excento_imss;
                }
                else{
                    this.maxWidthModal = '400px'
                    this.showPercepciones = false
                }
            }
            else if(tipoTabla == 'PERCEPCIONES-DEDUCIONES-OTROS_PAGOS'){
                this.complemento = data2;
                this.totalComplemento =  data2.total;
                this.estatusComplemento =  data2.estatus;
                this.repetirComplemento =  data2.repetir;
                if(data2.tipo_concepto == 'percepciones'){
                    this.maxWidthModal = '500px'
                    this.showPercepciones = true
                    this.isrExento  = data2.excento_isr;
                    this.imssExento = data2.excento_imss;
                }
                else{
                    this.maxWidthModal = '400px'
                    this.showPercepciones = false
                }
            }
            
            this.isLoading  = false;
            this.dialogEdit = true;
        },
        async guardarComplemento(){
            let parametros = {};
            this.isSaving = true
            parametros.id       = this.complemento.id;
            parametros.importe  = this.totalComplemento;
            parametros.estatus  = "AUTORIZADO";
            parametros.repetir  = false;
            parametros.tipo = "DESACUMULADO"

            if(this.showPercepciones){
                if(this.isrGravado<0){
                    Notify.ErrorToast("El valor de ISR Gravado debe ser mayor o igual a cero");
                    this.isSaving = false
                    return
                }
                if(this.imssGravado<0){
                    Notify.ErrorToast("El valor de IMSS Gravado debe ser mayor o igual a cero");
                    this.isSaving = false
                    return
                }

                parametros.gravado_isr = this.isrGravado
                parametros.exento_isr = this.isrExento
                parametros.gravado_imss = this.imssGravado
                parametros.exento_imss = this.imssExento
            }

            await apiPeriodoIncidencia.updateImporte(parametros).then(response => {
                Notify.Success("Operación exitosa", "El total del complemento se actualizo satisfactoriamente.");
                this.cerrarModal();
                this.isSaving = false
                this.getComplementos()
            })
            .catch(err => {
                this.isSaving = false
                if(typeof err.response.data.error === 'string'){
                    Notify.ErrorToast(err.response.data.error);
                }else{
                    let error = Object.values(err.response.data.error);
                    let leyenda = ``;

                    for (var i = 0; i < error.length; i++) {
                        leyenda+= `* ` + error[i] + `\n`;
                    }

                    Notify.ErrorToast(leyenda);
                }
            });
        },
        eliminarIncidencia(nombre, incidencia_id){
            Notify.Alert(
                "Eliminar incidencia",
                "¿Estás seguro que deseas eliminar la incidencia " + nombre + "?",
                "Eliminar",
                () => {
                    apiIncidenciasNominas.delete(incidencia_id).then(async response => {
                        Notify.Success("Operación exitosa", "La incidencia se eliminó satisfactoriamente.");
                        await this.getComplementos(this.modeloTipoGrupo)
                    })
                    .catch(err => {
                        if(typeof err.response.data.error === 'string'){
                            Notify.ErrorToast(err.response.data.error);
                        }else{
                            let error = Object.values(err.response.data.error);
                            let leyenda = ``;

                            for (var i = 0; i < error.length; i++) {
                                leyenda+= `* ` + error[i] + `\n`;
                            }

                            Notify.ErrorToast(leyenda);
                        }
                    });
                }
            )
        },
        eliminacionMasiva(){
            let formatIncidencias = [];
            this.selected_general.forEach(element => {
                formatIncidencias.push(element);
            });
            let parametros = { 
                periodo_incidencia:this.idPeriodoDeComplementos, 
                incidencias : formatIncidencias,
                tipo        : "DESACUMULADO"
            }
            Notify.Alert(
                "Eliminar incidencia(s)",
                "¿Estás seguro que deseas eliminar la(s) incidencia(s) seleccionada(s)?",
                "Eliminar",
                () => {
                    apiIncidenciasNominas.eliminacionMasiva(parametros).then(response => {
                        Notify.Success("Operación exitosa", "Incidencias seleccionadas actualizadas satisfactoriamente.");
                        this.getComplementos()
                        this.$refs.tablageneral.unselectAll();
                        this.selected_general = [];
                    })
                    .catch(err => {
                        if(typeof err.response.data.error === 'string'){
                            Notify.ErrorToast(err.response.data.error);
                        }else{
                            let error = Object.values(err.response.data.error);
                            let leyenda = ``;

                            for (var i = 0; i < error.length; i++) {
                                leyenda+= `* ` + error[i] + `\n`;
                            }

                            Notify.ErrorToast(leyenda);
                        }      
                    });
                }
            )
        },
        cerrarModal() {
            //this.dialog     = false;
            this.dialogEdit = false;
            this.isSaving   = false;
            this.dialogComplementos = false
            this.resetValues();
        },
        resetValues() {
            this.isrExento      = 0;
            this.imssExento     = 0;
            this.archivo = null;
            this.$nextTick(() => {
                if(this.$refs.form){
                    this.$refs.form.reset();
                }
            });
        },
        opcionAgrupar(opcion){
            this.modeloTipoGrupo= opcion.valor;
        },
        openModal() {
            let resolucion = screen.height;
            let alto = screen.height * 0.70;
            if (resolucion < 850) {
                alto = resolucion * 0.76;
            }

            this.$modal.show(ModalComplementos, {
                objPeriodoIncidencia : this.nominaDesacumulada,
                idPeriodoComplemento : this.idPeriodoDeComplementos,
                tipoPeriodoEspecial : this.tipoPeriodoEspecial,
                idPeriodo           : this.idPeriodo,
            }, {
                name: 'incidenciasModal',
                height: alto,
                width: 900,
                scrollable: false,
            }, {
                'before-close': (event) => {
                    this.getComplementos()
                }
            });
        },

        async exportar(tipo_doc = null) {
            let sefl = this;
            let url = "incidencias_nomina/reporte";
            const FileDownload = require("js-file-download");
            var today = new Date();
            let nombre = `${this.nominaDesacumulada.clave_tipo_nomina}-${this.nominaDesacumulada.nombre_empresa}.pdf`
    
            if(tipo_doc == 'xlsx')   
                nombre = `${this.nominaDesacumulada.clave_tipo_nomina}-${this.nominaDesacumulada.nombre_empresa}.xlsx`;
            
            let parametros = { 
                periodo_incidencia_id : this.idPeriodoDeComplementos , 
                tipo : tipo_doc, 
                orden:'SINORDEN', 
                usuario_id:this.datosLogin.id}

            const object1 = parametros;
            try {
                await Vue.axios({
                method: "GET",
                responseType: "blob",
                url: url,
                params: object1,
                }).then((response, status, xhr) => {
                    console.log(response)
                    FileDownload(response.data, nombre);
                });
            } catch (err) {
                var message = null;
                if(typeof err.response.data.error === 'string'){
                    Notify.ErrorToast(err.response.data.error);
                }else{
                    if(typeof err.response.data.error === 'string'){
                        Notify.ErrorToast(err.response.data.error);
                    }else{
                        let error = Object.values(err.response.data.error);
                        let leyenda = ``;

                        for (var i = 0; i < error.length; i++) {
                            leyenda+= `* ` + error[i] + `\n`;
                        }

                        Notify.ErrorToast(leyenda);
                    }
                }
            }
        },

        layoutComplementos(){
            this.loadingLottie =  true;
            this.fraseLoading = "Descargando";
            const FileDownload = require("js-file-download");
            let parametros = {
                cliente_id : this.nominaDesacumulada.cliente_id,
                periodo_incidencia_id : this.idPeriodoDeComplementos
            }

            let nombre;
            nombre = "Layout_complementos_de_nómina.xlsx";

            let urlCalculo = "incidencias_nomina/exportar-layout";

            try {
                Vue.axios({
                method: "POST",
                responseType: "blob",
                url: urlCalculo,
                data: parametros,
                }).then((response, status, xhr) => {
                    FileDownload(response.data, nombre);
                    this.loadingLottie = false;
                })
                .catch(async (error) =>{
                    this.loadingLottie = false;
                    let errorString = error.response.data;
                    if (
                        error.request.responseType === 'blob' &&
                        error.response.data instanceof Blob &&
                        error.response.data.type &&
                        error.response.data.type.toLowerCase().indexOf('json') != -1
                    ){
                        errorString = JSON.parse(await error.response.data.text());
                        console.log(errorString)

                        if(typeof errorString.error === 'string'){
                            Notify.ErrorToast(errorString.error);
                        }else{
                            let error = Object.values(errorString.error);
                            let leyenda = ``;

                            for (var i = 0; i < error.length; i++) {
                                leyenda+= `* ` + error[i] + `\n`;
                            }
                            Notify.ErrorToast(leyenda);
                        }
                    };
                });
            } 
            catch (err) {
                this.loadingLottie = false;
                Notify.ErrorToast("No se pudo realizar la petición");
                console.log(err);
                
            }
        },

        abrirModalLayoutEmpleados(tipo = null){
            this.isLoading = true
            this.imagen = "/static/icon/catalogos.png"
            this.isLoading = false
            this.isSaving = false
            this.dialogComplementos = true
            this.tipo = tipo
        },

        subirComplementos(){
            let self = this;
            this.$refs.form.validate().then(success => {
                this.isSaving = true;
                if(!success) {
                    this.isSaving = false;
                    return;
                }

                self.loadingLottie = true;
                self.fraseLoading = "Subiendo archivo";
                
                let formData = new FormData();
                formData.append("archivo", this.archivo);
                formData.append("cliente_id", this.nominaDesacumulada.cliente_id);
                formData.append("usuario_id", this.datosLogin.id);
                formData.append("tipo", "DESACUMULADO");
                formData.append("periodo_incidencia_id", this.idPeriodoDeComplementos);
                /* if(this.temporal){
                    formData.append("periodo_incidencia_id", this.periodoI.periodo_incidencia_id);
                }
                else{
                    formData.append("periodo_incidencia_id", this.periodoI.id);
                } */

                apiIncidenciasNominas.importarLayout(formData).then((response) => {
                    if (response.status == 200) {
                        Notify.Success("Operación exitosa", "La carga de complementos está lista.");
                        self.cerrarModal()
                        self.getComplementos()

                    }
                    else if(response.status == 202){
                        let formatErrores = response.data;
                        if(formatErrores.length != 0){
                            let nuevoObjeto = {}
                            formatErrores.forEach( x => {
                                if( !nuevoObjeto.hasOwnProperty(x.empleado_id)){
                                    nuevoObjeto[x.empleado_id] = {
                                        erroresComplementos: []
                                    }
                                }
                                    nuevoObjeto[x.empleado_id].erroresComplementos.push({
                                        empleado_nombre: x.empleado_nombre,
                                        departamento: x.departamento,
                                        complemento: x.complemento,
                                        error:x.error
                                    })
                                
                            })
                            self.erroresComplementos =nuevoObjeto;
                        }
                        self.dialogErrores = true;
                    }
                    else{

                    }
                    self.cerrarModal();
                    self.loadingLottie = false;
                })
                .catch (err => {
                    self.isSaving = false;
                    self.loadingLottie = false;
                    console.log(err)
                    /* if(typeof err.response.data.error === 'string'){
                        Notify.ErrorToast(err.response.data.error);
                    }else{
                        let error = Object.values(err.response.data.error);
                        let leyenda = ``;

                        for (var i = 0; i < error.length; i++) {
                            leyenda+= `* ` + error[i] + `\n`;
                        }
                        Notify.ErrorToast(leyenda);
                    } */
                })
            });
        },

        open() {
            document.getElementById("fileDocument").click();
        },

        cerrarModalErrores(){
            this.dialogErrores = false;
            this.erroresComplementos = [];
        },

    },
    async created() {
        this.urlAvatar          = urlImg.getURL();
        this.nominaDesacumulada = this.$session.get('nominaDesacumulada');
        this.datosLogin = this.$session.get('usuario');
        await this.getComplementos()
    },
    updated() {     
        
        if(this.modeloTipoGrupo == 'SINORDEN'){
            let tablaSinOrden = document.querySelector('#tablaSinOrden');
            if(tablaSinOrden){
                document.querySelector('#tablaSinOrden thead tr th:nth-child(1)').style.paddingRight   = "4px";
                document.querySelector('#tablaSinOrden thead tr th:nth-child(1)').style.width   = "80px";
                document.querySelector('#tablaSinOrden thead tr th:nth-child(2)').style.paddingRight   = "0px";
            }
        }
        let tablaDeducciones = document.querySelector('#tablaDeducciones');
        if(tablaDeducciones){
            document.querySelector('#tablaDeducciones thead tr th:nth-child(1)').style.paddingRight   = "0px";
            document.querySelector('#tablaDeducciones thead tr th:nth-child(1)').style.paddingTop   = "20px";
            document.querySelector('#tablaDeducciones thead tr th:nth-child(1)').style.paddingBottom   = "20px";
            
        }
        let tablaPercepciones = document.querySelector('#tablaPercepciones');
        if(tablaPercepciones){
            document.querySelector('#tablaPercepciones thead tr th:nth-child(1)').style.paddingRight   = "0px";
            document.querySelector('#tablaPercepciones thead tr th:nth-child(1)').style.paddingTop   = "20px";
            document.querySelector('#tablaPercepciones thead tr th:nth-child(1)').style.paddingBottom   = "20px";
        }
        let tablaOtrosPagos = document.querySelector('#tablaOtrosPagos');
        if(tablaOtrosPagos){
            document.querySelector('#tablaOtrosPagos thead tr th:nth-child(1)').style.paddingRight   = "0px";
            document.querySelector('#tablaOtrosPagos thead tr th:nth-child(1)').style.paddingTop   = "20px";
            document.querySelector('#tablaOtrosPagos thead tr th:nth-child(1)').style.paddingBottom   = "20px";
        }
        
    },

    
}
</script>
<style scoped>
    #padre {
        padding-left: 40%;
    }
    #loading {
        display: table-cell;
        vertical-align: middle;
    }
    
    .invertirColor {
        color: white;
        -webkit-text-stroke-width: 1px;
        -webkit-text-stroke-color: rgba(0,0,0,0.54);
    }
    .tamIcono {
        height: 24px !important;
        align-self: center !important;
    }
    .iconoDelgado {
        color: white;
        -webkit-text-stroke-width: 1px;
        -webkit-text-fill-color: rgba(0,0,0,0.54);
    }
    .botonModal.theme--light.v-btn:not(.v-btn--flat):not(.v-btn--text):not(.v-btn--outlined){
        display: none;
    }
    .tituloPeriodoIncidencia{
        font-family: 'Montserrat';
        font-style: normal;
        font-weight: 600;
        font-size: 16px;
        line-height: 20px;
        color: #101C5A;
    }
    .borde-tabla.v-data-table {
        border-radius: 20px !important;
    }
    .texto-tabla{
        font-family: 'Montserrat' !important;
        font-style: normal !important;
        font-weight: 500 !important;
        font-size: 12px !important;
        line-height: 18px !important;
        color: #828282 !important;
    }
    .list-item-titulo{
        font-family: 'Montserrat';
        font-style: normal;
        font-weight: 500;
        font-size: 14px;
        line-height: 18px;
        color: #828282;
    }
    .v-data-table td {
        font-size: 0.75rem; /* 0.75 rem = 12 px */
    }
    .btn-acciones:hover{
        color: #FFFFFF;    
    }
    .btn-agrupar-por {
        font-family: 'Montserrat';
        font-style: normal;
        font-weight: 600;
        font-size: 12px;
        line-height: 15px;
        /* padding-right: 10px !important; */
        text-transform: none;
        height: 38px !important;
        min-width: 140px !important;
    }
    .v-menu__content{
        border-radius: 15px;
        margin-top: 10px;
        box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.1);
    }

     /**
    * COMPONENT EXPANDABLE ROW
    */
    .tbl-incidencias tr:first-child td {
        padding-top: 15px;
    }
    .tbl-incidencias tr td {
        padding-right: 43px !important;
        padding-left: 43px !important;
        padding-top: 0px !important;
        padding-bottom: 0px !important;
    }

    .divider {
        border-top: 0.5px solid rgba(87, 87, 92, 0.4);
    }

    .tbl-child {
        box-shadow: none !important;
        width: 100%;
        border-collapse: collapse;
    }
    .tbl-child thead th {
        font-family: Montserrat;
        font-style: normal;
        font-weight: 500 !important;
        font-size: 14px !important;
        line-height: 17px !important;
        align-items: center;

        height: 42px;
        background: #101C5A05 !important;
        color: #101C5A;
        padding: 13px 0;
    }
    .tbl-child thead th:first-child,
    .tbl-child tbody td:first-child {
        padding-left: 150px !important;
    }
    .tbl-child tbody td:last-child {
        padding-right: 43px !important;
    }

    .tbl-child tbody td {
        padding-left: 0 !important;
    }


    .v-select__selection,
    .v-select__selection--comma,
    .v-select.v-text-field input {
        color: blue !important;
    }

    .textResultadoTotal{
        font-family: Montserrat;
        font-style: normal;
        font-weight: 600;
        font-size: 16px;
        line-height: 20px;
        color: #1E2144;
    }
    .numberResultadoTotal{
        font-family: Montserrat;
        font-style: normal;
        font-weight: bold;
        font-size: 16px;
        line-height: 20px;
        color: #1E2144;
    }
    [v-cloak] {
        display: none;
    }
    .border-gray-300{
        --tw-border-opacity: 1;
        border-color: rgba(209, 213, 219, var(--tw-border-opacity));
    }
    .titleModalErrores{
        font-family: Montserrat;
        font-style: normal;
        font-weight: 500;
        font-size: 22px;
        line-height: 18px;
        letter-spacing: 0.1px;
        color: #444975;
    }
    .emptyTableErrores{
        padding-top: 50px !important;
        padding-bottom: 50px !important;
        font-family: "Montserrat";
        font-style: normal;
        font-weight: 500;
        font-size: 18px;
        line-height: 22px;
        text-align: center !important;
        color: #c5c5c5;
    }
    .tbl-errores {
        border-collapse: collapse;
        width: 100%;
        margin-top: 15px;
        /* white-space: nowrap; */
        overflow-x: scroll;
        overflow-y: scroll;
    }

    .tbl-errores .tbl-header {
        background: #F6F6F6;
        border-radius: 10px;
        height: 58px;
    }
    .errorth {
        font-family: 'Montserrat';
        font-style: normal;
        font-weight: 500;
        font-size: 16px;
        color: #828282;
        line-height: 20px;
        padding: 8px;
        text-align: left !important;
    }
    .errortd {
        font-family: 'Montserrat';
        font-style: normal;
        font-weight: 500;
        font-size: 12px;
        color: #96999A;
        line-height: 15px;
        padding: 8px;
        text-align: left !important;
    }
    .textoTablaError01{
        font-family: 'Montserrat';
        font-style: normal;
        font-weight: 500;
        font-size: 14px;
        line-height: 17px;
        color: #1E2245;
        text-align: left !important;
    }
    .textoTablaError02{
        font-family: 'Montserrat';
        font-style: normal;
        font-weight: 500;
        font-size: 12px;
        line-height: 15px;
        color: #828282;
        text-align: left !important;
    }
    .textoTablaError03{
        font-family: 'Montserrat';
        font-style: normal;
        font-weight: 500;
        font-size: 14px;
        color: #EB5757;
        line-height: 17px;
        text-align: left !important;
    }
    .trDivider{
        border-bottom-style: solid; 
        border-bottom-width: 1px;
        border-bottom-color: #C4C4C4;
    }
    .tituloAcciones{
        font-family: 'Montserrat';
        font-style: normal;
        font-weight: 500;
        font-size: 14px;
        line-height: 18px;
        letter-spacing: 0.1px;
        color: #1E2245;
    }
    .menuClassAcciones{
        border-radius: 10px!important;
        margin-top:15px;
        background-color: #FFFFFF;
    }
    .cursor-not-allowed:hover{
        cursor:not-allowed !important
    }

    #btnModalErrores{
        display: none;
    }


    .btnCancelar {
        height: 42px !important;
        border-radius: 30px;
        text-transform: none;
        background:#D41010 !important;
        color:#FFFFFF !important;
        font-family: "Montserrat" !important;
        font-style: normal !important;
        font-weight: 600 !important;
        font-size: 14px !important;
        line-height: 17px !important;
    }

    .header-cancelar {
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-items: center;
    }
    .header-cancelar-h2 {
        font-family: "Montserrat";
        font-style: normal;
        font-weight: 600;
        font-size: 24px;
        line-height: 17px;
        color: #D41010;
    }
    .header-cancelar-h3 {
        font-family: "Montserrat";
        font-style: normal;
        font-weight: 600;
        font-size: 16px;
        line-height: 16px;
        color: #828282;
    }
</style>